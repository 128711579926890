const statesList = require('../data/states-list.json');
const currenciesList = require('../data/currencies.json');

export const mappingPermitFieldsToBackend = {
  'licenseNumber': 'number',
  'state': 'state',
  'country': 'country',
  'startDate': 'date_of_approval',
  'expDate': 'date_of_expiration',
  'category': 'category',
  'area': 'area',
  'scenario': 'scenario',
  'type': 'type',
  'class': 'class',
  'has_permission': 'understood',
  'less_than_249g': 'less_than_249g'
};

let mapping = {};
for (const key of Object.keys(mappingPermitFieldsToBackend)) {
  mapping[mappingPermitFieldsToBackend[key]] = key;
}

export const mappingPermitFieldsFromBackend = mapping;

export const mappingInsuranceFieldsToBackend = {
  'number': 'policy_number',
  'company': 'insurance_company',
  'total': 'sum_insured',
  'currency': 'currency',
  'expDate': 'expiration_date'
};

mapping = {};
for (const key of Object.keys(mappingInsuranceFieldsToBackend)) {
  mapping[mappingInsuranceFieldsToBackend[key]] = key;
}

export const mappingInsuranceFieldsFromBackend = mapping;

export const currencies = (() => {
  const _currencies = [];
  for (const key of  Object.keys(currenciesList)) {
    _currencies.push({
      code: key,
      name: currenciesList[key]
    });
  }
  return _currencies;
})();

export function getStates(country: string, language: string) {
  const states = [];
  for (const key of Object.keys(statesList[country][language])) {
    states.push({
      value: key,
      name: statesList[country][language][key]
    });
  }
  return states;
}

export function isCountryWithStates(country: string) {
  return Object.keys(statesList).indexOf(country) > -1;
}
